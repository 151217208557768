@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
