.custom-carousel .control-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Adjust space as needed */
}

.custom-carousel .control-dots .dot {
  background-color: #e5e7eb; /* Default gray color */
  height: 30px; /* Height for number indicators */
  width: 30px; /* Width for number indicators */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px; /* Space between dots */
  border-radius: 50%; /* Make them circular */
  font-weight: bold; /* Make numbers bold */
  color: #ffffff; /* Number color */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s; /* Smooth transition */
}

.custom-carousel .control-dots .dot.selected {
  background-color: #f58634; /* Active color */
}
